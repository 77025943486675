import { API } from "@/services/ServerConstant"
export const

    API_URL = API,

    TOKEN   = 'efsToken',
    PREFIX  = 'nco/',

    LOGGED_USER     = PREFIX + 'loggedUser',
    LOGIN           = PREFIX + 'login',
    LOGOUT          = PREFIX + 'logout',
    USERS           = PREFIX + 'users',
    SITES           = PREFIX + 'sites',

    CHANGE_PASSWORD = PREFIX + 'changePassword',

    GET_SITES = PREFIX + 'getSites',
    GET_SITES_DETAILS = PREFIX + 'getSiteDetails',
    GET_SITE_FOLDERS = PREFIX + 'getSiteFolders',

    GET_FOLDER_ITEMS = PREFIX + 'getFolderItems',
    COUNT_FOLDER_ITEMS = PREFIX + 'countFolderItems',

    ADD_FOLDER = PREFIX + 'addFolder',
    ADD_FILE = PREFIX + 'addFile',

    DELETE_ITEM = PREFIX + 'deleteItems',
    DELETE_MULTIPLE = PREFIX + 'deleteMultiple',
    DOWNLOAD_FOLDER = PREFIX + 'downloadFolder',
    DOWNLOAD_FILE = PREFIX + 'downloadFile',

    GET_BREADCRUMS = PREFIX + 'breadcrumbs',
    SEARCH_ITEMS = PREFIX + 'searchItems',

    SITE_USERS = PREFIX + 'getSiteUserRoles',
    SITE_UNASSIGNED_USERS = PREFIX + 'getUnassignedUsers',
    REMOVE_SITE_USERS = PREFIX + 'removeSiteUser',
    ADD_SITE_USERS = PREFIX + 'assignSiteUsers',
    EDIT_SITE_USERS_ROLE = PREFIX + 'changeSiteUserRole',
    GET_SITE_USERS_ROLE = PREFIX + 'getUserRole',

    ALL_ITEM_COMMENTS = PREFIX + 'getItemComments',
    ITEM_COMMENTS = PREFIX + 'itemComments',

    ITEM = PREFIX + 'items',
    UPDATE_ITEM = PREFIX + 'updateItem',
    GET_BASE64_PDF = PREFIX + 'getBase64Pdf',
    FETCH_ACTIVITY_LOG = PREFIX + 'getActivityLogs',
    DOWNLOAD_MULTIPLE = PREFIX + 'downloadMultiple',
    GET_CREATORS = PREFIX + 'getCreatorsOnSearch',
    GET_EXTENSIONS = PREFIX + 'getExtensionOnSearch',
    MOVE_MULTIPLE = PREFIX + 'moveMultiple',
    COPY_MULTIPLE = PREFIX + 'copyMultiple'