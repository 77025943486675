import axios from 'axios'
import {
    SITE_USERS,
    SITE_UNASSIGNED_USERS,
    REMOVE_SITE_USERS,
    ADD_SITE_USERS,
    EDIT_SITE_USERS_ROLE, GET_SITE_USERS_ROLE
} from "@/services/RouteConstantsService";

export const SiteUserService = {
    getAllSiteUsers (siteId) {
        return axios.get(`${SITE_USERS}/${siteId}`)
    },
    deleteSiteUser (params) {
        return axios.post(REMOVE_SITE_USERS, params)
    },
    getAllUnassignedUsers (params) {
        return axios.post(SITE_UNASSIGNED_USERS, params)
    },
    addSiteUsers (params) {
        return axios.post(ADD_SITE_USERS, params)
    },
    editSiteUserRole (params) {
        return axios.post(EDIT_SITE_USERS_ROLE, params)
    },
    async getSiteUserRole (siteId) {
        return await axios.get(`${GET_SITE_USERS_ROLE}/${siteId}`)
    },

};

export default {SiteUserService};
