import axios from "axios";
import {
    ADD_FILE,
    ADD_FOLDER, COPY_MULTIPLE,
    COUNT_FOLDER_ITEMS,
    DELETE_ITEM,
    DELETE_MULTIPLE,
    DOWNLOAD_FILE,
    DOWNLOAD_FOLDER,
    DOWNLOAD_MULTIPLE,
    GET_BASE64_PDF,
    GET_BREADCRUMS, GET_CREATORS, GET_EXTENSIONS,
    GET_FOLDER_ITEMS,
    GET_SITE_FOLDERS,
    ITEM, MOVE_MULTIPLE,
    SEARCH_ITEMS,
    UPDATE_ITEM
} from "@/services/RouteConstantsService";

export const ItemService = {
    async getSiteFolders(params) {
        return await axios.post(GET_SITE_FOLDERS, params)
    },
    async getFolderItems(params) {
        return await axios.post(GET_FOLDER_ITEMS, params);
    },
    async countFolderItems(params) {
        return await axios.post(COUNT_FOLDER_ITEMS, params);
    },
    async addFolder(params) {
        return await axios.post(ADD_FOLDER, params);
    },
    async addFile(params) {
        return await axios.post(ADD_FILE, params);
    },
    async deleteItems(itemId) {
        return await axios.delete(`${DELETE_ITEM}/${itemId}`);
    },
    async deleteMultipleItems(multipleIds){
        return await axios.post(DELETE_MULTIPLE,{id:multipleIds});
    },
    downloadFolder(folderId) {
        return axios({
            url: `${DOWNLOAD_FOLDER}/${folderId}`,
            method: 'GET',
            responseType: 'text'
        })
    },
    downloadFile(fileId) {
        return axios({
            url: `${DOWNLOAD_FILE}/${fileId}`,
            method: 'GET',
            responseType: 'text'
        });
    },
    getItemDetails (itemId) {
        return axios.get(`${ITEM}/${itemId}`)
    },
    async updateItem(params) {
        return await axios.post(UPDATE_ITEM, params)
    },
    async getBase64Pdf(itemId) {
        return axios({
            url: `${GET_BASE64_PDF}/${itemId}`,
            method: 'GET',
            responseType: 'text'
        });
    },

    async downloadAsZip(params){
      return axios({
          url: DOWNLOAD_MULTIPLE,
          method: 'POST',
          responseType: 'blob',
          data:params,
      });
    },

    getBreadcrumbs (folderId) {
        return axios.get(`${GET_BREADCRUMS}/${folderId}`)
    },

    searchItems (params) {
        return axios.post(SEARCH_ITEMS, params)
    },

    getCreators(params){
        return axios.post(GET_CREATORS,params);
    },

    getExtensions(params){
        return axios.post(GET_EXTENSIONS,params);
    },
    moveMultiple(params){
        return axios.post(MOVE_MULTIPLE,params);
    },
    copyMultiple(params){
        return axios.post(COPY_MULTIPLE,params);
    }
};

export default {ItemService};
