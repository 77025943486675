<template>
  <ProgressLoader v-show="showLoading"></ProgressLoader>
  <router-view/>
</template>

<script>
import {mapState} from "vuex";
import ProgressLoader from "@/components/app-layout/ProgressLoader";

export default {
  components: {
    ProgressLoader
  },
  computed: {
    ...mapState(['showLoading'])
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* Firefox */
* {
  scrollbar-width: thick; /* none | auto  */
  scrollbar-color: #8cc3aa whitesmoke;
}

/* The first applies to the thumb of the scrollbar, the second to the track. */
/* Firefox */

/* Chrome, Edge, Safari */
*::-webkit-scrollbar {
  width: 14px; /* vertical scrollbar */
  height: 14px; /* horizontal scrollbar */
}

*::-webkit-scrollbar-track {
  background: whitesmoke;
}

*::-webkit-scrollbar-thumb {
  background: #8cc3aa;
  border-radius: 14px;
  border: calc(14px / 14) solid #ccc;
}

*::-webkit-scrollbar-thumb:hover {
  background: #198754;
}
</style>
