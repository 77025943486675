/** state **/
import {AuthenticationService} from "@/services/AuthenticationService";
import {TOKEN} from "@/services/RouteConstantsService";

const state = {
    authToken: null,
    authUser: {}
};

/** mutations **/
const mutations = {
    SET_AUTH_USER: (state, userApi) => {
        state.authUser = userApi
    },
    SET_TOKEN (state, token) {
        state.authToken = token
    }
};

/** actions **/
const actions = {
    async userLogin ({ dispatch }, payload) {
        const response = await AuthenticationService.authenticate(payload)
        return dispatch('attempt', response.data.access_token)
    },

    async attempt ({ commit }, token) {
        if (token) {
            commit('SET_TOKEN', token)
        } if (!state.authToken) {
            return
        }
        try {
            const response = await AuthenticationService.getLoggedUser()
            commit('SET_AUTH_USER', response.data.data)
        } catch (e) {
            commit('SET_TOKEN', null)
            commit('SET_AUTH_USER', null)
        }
    }
};

/** getters **/
const getters = {
    authUser: (state) => state.authUser,
    authToken: (state) => state.authToken
};

/** export **/
export default {state, actions, mutations, getters};
