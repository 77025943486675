const state = {
    pagination:{
        from: 1,
        to: 9,
        currentPage: 1,
        lastPage: 0,
        perPage: 15,
        total: 0,
    }

}

const mutations = {
    SET_PAGINATION: (state, metadata) => {
        state.pagination={
            from: metadata.from,
            to: metadata.to,
            currentPage: metadata.current_page,
            lastPage: metadata.last_page,
            perPage: metadata.per_page,
            total: metadata.total,
        }
    },
}

const getters = {
    pagination:(state)=>state.pagination
}


export default {
    state, getters, mutations
}



