/** state **/
import {UserService} from "@/services/UserService";

const state = {
    users: []
};

/** mutations **/
const mutations = {
    SET_USERS (state, apiUsers) {
        state.users = apiUsers
    }
};

/** actions **/
const actions = {
    async getAllUsers ({ commit }) {
        const response = await UserService.userIndex()
        commit('SET_USERS', response.data)
    }
};

/** getters **/
const getters = {
    users: (state) => state.users.data
};

/** export **/
export default {state, actions, mutations, getters};
