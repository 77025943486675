import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/defaults/HomeView.vue'
import LoginView from "@/views/LoginView";
import {TOKEN} from "@/services/RouteConstantsService";

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/home',
    name: 'DashboardView',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardView'),
    meta: { requiresAuth: true }
  },
  {
    path: '/site/',
    name: 'SitesView',
    component: () => import(/* webpackChunkName: "sites" */ '@/views/SitesView'),
    meta: { requiresAuth: true },
    children: [
      {
        path: ':siteId/items/:parentId?/',
        name: 'SiteShowView',
        component: () => import(/* webpackChunkName: "siteShow" */ '@/views/SiteShowView'),
        props: true,
      },
      {
        path: ':siteId/users',
        name: 'SiteMembersView',
        component: () => import(/* webpackChunkName: "siteMembers" */ '@/views/site/SiteMembersView'),
        props: true,
      }
    ]
  },
  {
    path: '/item/:siteId/:itemId/',
    name: 'ItemIndexView',
    component: () => import(/* webpackChunkName: "sites" */ '@/views/site/ItemIndexView'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'folder',
        name: 'FolderView',
        component: () => import(/* webpackChunkName: "siteShow" */ '@/views/site/FolderView'),
        props: true,
      },
      {
        path: 'file',
        name: 'FileView',
        component: () => import(/* webpackChunkName: "siteMembers" */ '@/views/site/FileView'),
        props: true,
      }
    ]
  },
  {
    path: '/admin/',
    name: 'AdminToolsView',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminToolsView'),
    meta: { requiresAuth: true },
    redirect: '/admin/list',
    children: [
      {
        path: 'list',
        name: 'UserListView',
        component: () => import(/* webpackChunkName: "siteShow" */ '@/views/admin/UserListView')
      }
    ]
  },


  //  testing routes
  {
    path: '/h',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/defaults/AboutView.vue')
  },
  {
    path: '/item/search/:search?',
    name: 'SearchComponent',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/SearchTabComponent'),
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem(TOKEN) != null) {
      next();
      return
    }
    next('/')
  } else {
    next();
  }
});

export default router
