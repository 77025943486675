import axios from "axios";
import {CHANGE_PASSWORD, USERS} from "@/services/RouteConstantsService";

export const UserService = {
    changeAcctPassword(params) {
        return axios.post(CHANGE_PASSWORD, params)
    },
    userIndex () {
        return axios.get(USERS)
    },
    userAdd (params) {
        return axios.post(USERS, params)
    },
    userRemove (userId) {
        return axios.delete(`${USERS}/${userId}`)
    }
};

export default {UserService};
