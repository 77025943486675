/** state **/
import {SiteUserService} from "@/services/SiteUserService";

const state = {
    siteUsers: {},
    unassignedSiteUsers: [],
    siteUserRole: [],
};

/** mutations **/
const mutations = {
    SET_SITE_USERS (state, apiSiteUsers) {
        state.siteUsers = apiSiteUsers
    },
    SET_UNASSIGNED_SITE_USERS (state, apiUnassignedSiteUsers) {
        state.unassignedSiteUsers = apiUnassignedSiteUsers
    },
    SET_SITE_USER_ROLE (state, apiSiteUserRole) {
        state.siteUserRole = apiSiteUserRole
    },
};

/** actions **/
const actions = {
    async getSiteAllUsers ({ commit }, siteId) {
        const response = await SiteUserService.getAllSiteUsers(siteId)
        commit('SET_SITE_USERS', response.data)
    },
    async getSiteAllUnassignedUsers ({ commit }, params) {
        const response = await SiteUserService.getAllUnassignedUsers(params)
        commit('SET_UNASSIGNED_SITE_USERS', response.data)
    },
    async addSiteUsers ({ commit }, params) {
        const response = await SiteUserService.addSiteUsers(params)
        commit('SET_SITE_USERS', response.data)
    },
    async getUserRole ({ commit }, siteId) {
        const response = await SiteUserService.getSiteUserRole(siteId)
        commit('SET_SITE_USER_ROLE', response.data)
    },
};

/** getters **/
const getters = {
    siteAllUsers: (state) => state.siteUsers,
    siteUnassignedUsers: (state) => state.unassignedSiteUsers,
    siteUserRole: (state) => state.siteUserRole,
};

/** export **/
export default {state, actions, mutations, getters};
