/** state **/
import {CommentService} from "@/services/CommentService";

const state = {
    itemComments: []
};

/** mutations **/
const mutations = {
    SET_ITEM_COMMENTS (state, apiItemComments) {
        state.itemComments = apiItemComments
    }
};

/** actions **/
const actions = {
    async getItemComments ({ commit }, itemId) {
        const response = await CommentService.getItemComments(itemId)
        commit('SET_ITEM_COMMENTS', response.data)
    }
};

/** getters **/
const getters = {
    itemComments: (state) => state.itemComments
};

/** export **/
export default {state, actions, mutations, getters};
