import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * bootstrap declaration
 * */
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

/**
 * fontawesome import for declaration
 * */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

/**
 * sweetalert 2 declaration
 * */

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/**
 * axios call and config
 * */
import {GlobalService} from "@/services/GlobalService";
GlobalService.initializeAxios();
import axios from 'axios'
store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'SET_TOKEN':
            if (mutation.payload) {
                axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`
                localStorage.setItem('efsToken', mutation.payload)
            } else {
                axios.defaults.headers.common.Authorization = null
                localStorage.removeItem('efsToken')
            }

            break
    }
})

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
    closeButtonColor: '#68796e',
    denyButtonColor: '#ffc012',
};

library.add(fas)


store.dispatch('attempt',
    localStorage.getItem('efsToken')).then(() => createApp(App)
    .use(store)
    .use(router)
    .use(GlobalService.initializeAxios)
    .use(VueSweetalert2, options)
    .component('fai', FontAwesomeIcon)
    .mount('#app'))
