/** state **/
import {ActivityLogService} from "@/services/ActivityLogService";

const state = {
    activityLogs: [],
};

/** mutations **/
const mutations = {
    SET_ACTIVITY_LOGS: (state, logsApi) => {
        state.activityLogs = logsApi
    },
};

/** actions **/
const actions = {
    async getActivityLogs({commit}, params) {
        const response = await ActivityLogService.fetchActivityLogs(params)
        commit('SET_ACTIVITY_LOGS', response.data)
    },
};

/** getters **/
const getters = {
    activityLogs: (state) => state.activityLogs,
};

/** export **/
export default {state, actions, mutations, getters};
