import axios from 'axios';
import {API_URL, TOKEN} from "@/services/RouteConstantsService";

export const GlobalService = {
    initializeAxios() {
        axios.defaults.baseURL = API_URL
        axios.interceptors.request.use(
            (config)=>{
                let token = localStorage.getItem(TOKEN);
                if(token){
                    axios.defaults.headers.common.Authorization =`Bearer ${token}`;
                    config.headers['Authorization'] =`Bearer ${token}`;
                }
                return config;
            },
            (error)=>{
                return Promise.reject(error);
            }
        );
    },
}

export default { GlobalService }
