/** state **/
import {SiteService} from "@/services/SiteService";

const state = {
    sites: [],
    site: {}
};

/** mutations **/
const mutations = {
    SET_SITES: (state, sitesApi) => {
        state.sites = sitesApi
    },
    SET_SITE_DETAILS: (state, siteApi) => {
        state.site = siteApi
    }

};

/** actions **/
const actions = {
    async getSites({commit}, params) {
        const response = await SiteService.getSiteList(params)
        commit('SET_SITES', response.data)
    },
    async getSiteDetails({commit}, params) {
        const response = await SiteService.getSiteDetail(params)
        commit('SET_SITE_DETAILS', response.data)
    }
};

/** getters **/
const getters = {
    sites: (state) => {
        function compare(a, b) {
            return a.site_name.localeCompare(b.site_name);
        }
        return state.sites.sort(compare);
    },
    site: (state) => state.site
};

/** export **/
export default {state, actions, mutations, getters};
