<template>
  <div class="container-fluid bg-light vh-100 d-flex align-items-center text-start">
    <div class="row no-gutters justify-content-center w-100">
      <div class="col-12 col-sm-9 col-md-7 col-lg-5">
        <div class="card shadow-sm">
          <div class="card-body p-4">
            <div class="row g-0 align-items-center">
              <div class="col-auto">
                <img :src="logo" alt="NCO EFS" style="width: 5rem">
              </div>
              <div class="col-auto ps-2">
                <h1 class="h1 fw-bold mb-0 text-success text-opacity-75">NCO EFS</h1>
              </div>
              <div class="col-12 py-3">
                <h4 class="h4 mb-0 text-opacity-75">Sign in to your account</h4>
              </div>
            </div>
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="loginUsername" class="form-label mb-0">Username</label>
                <input type="text" class="form-control" id="loginUsername" aria-describedby="usernameHelp"
                       placeholder="Username" v-model="credentials.username">
                <div id="usernameHelp" class="form-text">
                  <small> Never share you username to others </small>
                </div>
              </div>
              <label for="loginPassword" class="form-label mb-0">Password</label>
              <div class="mb-3 input-group">
                <input :type="passwordDetails.type"
                       class="form-control" id="loginPassword" placeholder="Password"
                       v-model="credentials.password">
                <button class="btn btn-outline-secondary" type="button" id="showPasswordBtn" @click="showPassword">
                  <fai :icon="passwordDetails.icon"/>
                </button>
              </div>
              <button type="submit" class="btn btn-primary mt-3 w-100">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../assets/imgs/nco.png'
import store from "@/store";
import router from "@/router";
import swal from "sweetalert2";
import {mapActions} from "vuex";
export default {
  name: "LoginView",
  data () {
    return {
      logo,
      credentials: {
        username: null,
        password: null
      },
      passwordDetails: {
        type: 'password',
        icon: 'eye'
      }
    }
  },
  methods: {
    ...mapActions(['userLogin']),
    async login() {
      store.commit('SET_LOADING_SPINNER', true)
      await this.userLogin(this.credentials).then(() => {
        store.commit('SET_LOADING_SPINNER', false)
        swal.fire('Successfully Login', '', 'success')
        router.push({ name: 'DashboardView' })
      }).catch((error) => {
        store.commit('SET_LOADING_SPINNER', false)
        swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              text: error.response.data
            })
      })
    },
    showPassword () {
      this.passwordDetails.type = this.passwordDetails.type === 'password' ? 'text' : 'password'
      this.passwordDetails.icon = this.passwordDetails.icon === 'eye' ? 'eye-slash' : 'eye'
    }

  }
}
</script>

<style scoped lang="scss">

</style>
