/** state **/
import {ItemService} from "@/services/ItemService";

const state = {
    item: {},
    folders: [],
    folderChildren: [],
    folderChildrenCount: '',
    breadcrumbs: [],
    searchedItems: [],
    creators:[],
    extensions:[]
};

/** mutations **/
const mutations = {
    SET_ITEM (state, apiItem) {
        state.item = apiItem
    },
    SET_FOLDERS: (state, folderApis) => {
        state.folders = folderApis
    },
    SET_FOLDER_CHILDREN: (state, folderChildrenApis) => {
        state.folderChildren = folderChildrenApis
    },
    SET_COUNT_FOLDER_CHILDREN: (state, folderChildrenCountApis) => {
        state.folderChildrenCount = folderChildrenCountApis
    },
    SET_BREADCRUMBS: (state, folderBreadcrumbsApis) => {
        state.breadcrumbs = folderBreadcrumbsApis
    },
    SET_SEARCHED_ITEMS: (state, folderFilesApis) => {
        state.searchedItems = folderFilesApis
    },
    SET_CREATORS: (state,creators) => {
        state.creators=creators
    },
    SET_EXTENSION: (state,extensions) => {
        state.extensions=extensions
    },
};

/** actions **/
const actions = {
    async getItem ({ commit }, itemId) {
        const response = await ItemService.getItemDetails(itemId)
        commit('SET_ITEM', response.data)
    },
    async getSiteFolders({commit}, params) {
        const response = await ItemService.getSiteFolders(params)
        commit('SET_FOLDERS', response.data)
        return response.data;
    },
    async getFolderChildren({commit}, params) {
        const response = await ItemService.getFolderItems(params)
        commit('SET_FOLDER_CHILDREN', response.data)
    },
    async countFolderChildren({commit}, params) {
        const response = await ItemService.countFolderItems(params)
        commit('SET_COUNT_FOLDER_CHILDREN', response.data)
    },
    async getBreadcrumbs({commit}, folderId) {
        const response = await ItemService.getBreadcrumbs(folderId)
        commit ('SET_BREADCRUMBS', response.data)
    },
    async getSearchFolderItems({commit}, params) {
        const response = await ItemService.searchItems(params)
        commit ('SET_SEARCHED_ITEMS', response.data.data)
        commit ('SET_PAGINATION',response.data.meta);
    },

    async getCreators({commit},params){
        const response = await ItemService.getCreators(params);
        commit('SET_CREATORS',response.data.data);
    },
    async getExtensions({commit},params){
        const response = await ItemService.getExtensions(params);
        commit('SET_EXTENSION',response.data.data);
    },
};

/** getters **/
const getters = {
    item: (state) => state.item,
    folders: (state) => state.folders,
    folderChildren: (state) => state.folderChildren,
    folderChildrenCount: (state) => state.folderChildrenCount,
    breadcrumbs: (state) => state.breadcrumbs,
    searchedItems: (state) => state.searchedItems,
    creators : (state)=>state.creators,
    extensions: (state)=>state.extensions
};

/** export **/
export default {state, actions, mutations, getters};
