import axios from 'axios'
import {ALL_ITEM_COMMENTS, ITEM_COMMENTS} from "@/services/RouteConstantsService";

export const CommentService = {
    getItemComments (itemId) {
        return axios.get(`${ALL_ITEM_COMMENTS}/${itemId}`)
    },

    addItemComments (params) {
        return axios.post(`${ITEM_COMMENTS}`, params)
    },

    removeItemComments (itemId) {
        return axios.delete(`${ITEM_COMMENTS}/${itemId}`)
    },

};

export default { CommentService };
