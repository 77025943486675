import { createStore } from 'vuex'
import LoginModule from "@/store/modules/LoginModule";
import SiteModule from "@/store/modules/SiteModule";
import ItemModule from "@/store/modules/ItemModule";
import SiteUserModule from "@/store/modules/SiteUserModule";
import CommentModule from "@/store/modules/CommentModule";
import UserModule from "@/store/modules/UserModule";
import ActivityLogModule from "@/store/modules/ActivityLogModule";
import PaginationModule from "@/store/modules/PaginationModule";

export default createStore({
  state: {
    showLoading: false
  },
  getters: {
  },
  mutations: {
    SET_LOADING_SPINNER (state, status) {
      state.showLoading = status
    }
  },
  actions: {
  },
  modules: {
    LoginModule,
    SiteModule,
    ItemModule,
    SiteUserModule,
    CommentModule,
    UserModule,
    ActivityLogModule,
    PaginationModule
  }
})
