import axios from 'axios'

import {LOGIN, LOGOUT, LOGGED_USER, TOKEN} from "@/services/RouteConstantsService";

export const AuthenticationService = {

    async authenticate(credentials) {
        return await axios.post(LOGIN, credentials)
    },

    getLoggedUser () {
        return axios.get(LOGGED_USER)
    },

    async logout() {
        return await axios.post(LOGOUT).then((response) => {
            this.deleteHeader()
            this.clearStorage()
            return true
        }).catch(error => {
            return false
        })
    },

    setHeader(token) {
        axios.defaults.headers.common['Authorization'] =`Bearer ${token}`;
    },

    deleteHeader(){
        delete axios.defaults.headers.common['Authorization'];
    },

    clearStorage() {
        localStorage.clear(TOKEN_NAME);
        localStorage.clear(USER_DETAILS);
    }

}
export default { AuthenticationService }
