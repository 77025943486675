<template>
  <div class="home">
    <img alt="Vue logo" src="../../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    {{authToken}},{{authUser}},
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/defaults/HelloWorld.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'HomeView',
  computed: {
    ...mapGetters(['authToken','authUser'])
  },
  methods: {
    ...mapActions(['userLogin'])
  },
  components: {
    HelloWorld
  }
}
</script>
