import axios from 'axios'
import {GET_SITES, GET_SITES_DETAILS, SITES} from "@/services/RouteConstantsService";

export const SiteService = {
    async getSiteList(params) {
        return await axios.post(GET_SITES, params)
    },
    async getSiteDetail(params) {
        return await axios.post(GET_SITES_DETAILS, params)
    },
    async addOneSite (newSiteDetails) {
        return await  axios.post(SITES, newSiteDetails)
    },
};

export default {SiteService};
